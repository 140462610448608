/* ErrorBoundary.css */
.error-boundary {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #183253;
}

.error-container {
  text-align: center;
  background: #0D253F;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.error-container h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #e74c3c;
}

.error-container p {
  font-size: 1.1rem;
  color: #ecf0f1;
  margin-bottom: 20px;
}

.refresh-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.refresh-button:hover {
  background-color: #2980b9;
}

.goback-btn{
  margin-left: 10px;
}

@media (max-width: 768px) {
  .error-container {
    padding: 20px;
  }

  .error-container h1 {
    font-size: 2rem;
  }

  .refresh-button {
    padding: 8px 16px;
  }
}
