@import '~bootstrap-icons/font/bootstrap-icons.css';

/* Navbar - start */
.scrolled-down {
  transform: translateY(-48%);
  transition: all 0.3s ease-in-out;
}

.scrolled-up {
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

.nav-down {
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
}

.nav-up {
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}

/* Sidebar - start */

@keyframes sideBarHideAnimation {
  0% {
    transform: translateX(0);
    display: block;
  }

  /* 99% {
      transform: translateX(-100%);
      display: block;
  } */

  100% {
    transform: translateX(-100%);
    display: none;
  }
}

.sidebar-hide {
  animation: sideBarHideAnimation 0.3s forwards;
}

@keyframes sideBarShowAnimation {
  0% {
    transform: translateX(-100%);
    display: none;
  }

  /* 99% {
      transform: translateX(0);
      visibility: hidden;
  } */

  100% {
    transform: translateX(0);
    display: block;
  }
}

.sidebar-show {
  animation: sideBarShowAnimation 0.3s forwards;
}

.sidebar-toggler {
  z-index: 2000;
}

.transition-width {
  transition: width 0.3s ease-in-out;
}

/* .hidden-div {
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease-in-out, padding 0.3s ease-in-out;
} */

/* Sidebar - end */

.main-sessions {
  display: flex;
  flex-direction: column;
  /* overflow-y: scroll; */
  z-index: 200;
}

.main-screen {
  height: 100vh;
  width: 100%;
  white-space: nowrap;
}

.inner-nav {
  top: 57px;
}

.hubview-section {
  position: relative;
  top: 182px;
}

/* -------------------------------------------------------------------- */

.dropdown-item:hover {
  background: #343F4C
}

/* searchbox */
.custom-search-box {
  background-color: #42484C;
  border-radius: 16px;
}

.custom-search-box:focus {
  background-color: #42484C;
  box-shadow: none;
}

.custom-search-button {
  background-color: #42484C;
  border-radius: 16px;
}

/* ----------------------------------------------------------------------------   */
/* Login */
.login {
  background: #111D2D;
}

.inputs {
  background: #343F4C;
}

.inputs:focus {
  background: #343F4C;
  color: #e1dfdf;
}

.form-control::placeholder {
  color: #9e9999;
}

.custom-icon-size {
  font-size: 4.0rem !important;
}

/* ----------------------------------------------------------------------------   */
/* MainContent.css */
.image-container {
  height: 700px;
  /* Set the initial height */
  overflow: hidden;
  /* Hide overflow to prevent image from going beyond its container */
}

.zoom-image {
  transition: transform 0.3s ease-out;
  /* Add a smooth transition effect */
  transform-origin: center bottom;
  /* Zoom from the bottom-center */
}

.initial-size {
  width: 100%;
  /* Set the initial width to 100% */
  object-fit: cover;
  /* Maintain aspect ratio while covering the entire container */
}

.placeholder-white::placeholder {
  color: white;
}

.tint-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
  /* Ensure it's behind the content */
}

/* ----------------------------------------------------------------------------   */
/* Scrollbar.css */
::-webkit-scrollbar {
  width: 10px;
  /* Set the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the scrollbar thumb */
  border-radius: 5px;
  /* Set the border-radius of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Set the color of the scrollbar thumb on hover */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Set the color of the scrollbar track */
}

::-webkit-scrollbar-track:hover {
  background-color: #ccc;
}

/* ----------------------------------------------------------------------------   */
/* HomePage.css */
.dashboard-image {
  background-image: linear-gradient(#14436B, #1C1E37);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  width: 100%;
  height: 100%;
}

.dashboard-image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.dashboard-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.start-date {
  width: 250px;
}

.react-datepicker-wrapper {
  width: 120px;
}

.shade-circle {
  display: inline-block;
  width: 10px;
  /* Adjust size as needed */
  height: 10px;
  /* Adjust size as needed */
  border-radius: 50%;
  margin-right: 8px;
  /* Adjust spacing between circle and label */
}

/* Custom CSS for styling the calendar */
input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  /* Inverts the colors of the calendar icon */
}

/* ----------------------------------------------------------------------------   */
/* Policy.css */

.policy-background {
  background-image: linear-gradient(#14436B, #1C1E37);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* ----------------------------------------------------------------------------   */
/* listview.css */
.list-view {
  background-color: rgba(139, 136, 136, 0.25);
}

.custom-table .table-striped,
.custom-table tr td,
.custom-table tr th {
  background-color: rgba(24, 29, 38, 0.1) !important;
  color: rgb(238, 236, 236) !important;
}

.img-cell {
  width: 60px;
  height: 20px;
}

/* ----------------------------------------------------------------------------   */
/* Hubview.css */

.text-ad {
  height: 100px;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.overlay {
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.heatmap-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  /* Allow mouse events to pass through */
}

.heatmap-color {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  /* Allow mouse events to pass through */
}


/* .img-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit:fill;
} */

/* .img-container:hover .overlay {
  opacity: 1;
} */

.hover-text {
  font-size: 2rem;
}

.overlay p {
  font-size: 1em;
  margin: 0;
}


.card-body-text {
  font-size: 12px;
}

/* .infinite-scroll{
  margin-left: 15px;
  margin-right: 15px;
} */

@media (min-width: 768px) and (max-width: 991.98px) {
  .infinite-scroll {
    margin-left: 8px;
  }
}

.card-modal {
  background-color: rgba(82, 84, 102);
}

.dropdown-menu-end {
  background-color: rgba(82, 84, 102);
  padding: 0%;
}

.card-badge {
  background-color: #ff7b01;
}

.custom-opacity {
  background-color: rgba(176, 173, 173, 0.25);
}

.panel-title {
  font-size: 12px;
}

.panel-body {
  background-color: rgba(139, 136, 136, 0.25);
  transition: max-height 0.3s ease-out;
  overflow: hidden;
}

.panel-body.panel-collapsed {
  max-height: 0;
}

.panel-reloading {
  opacity: 0.5;
  pointer-events: none;
}

.btn-icon {
  margin-inline-end: 4px;
  width: 15px;
  height: 20px;
  padding-right: 12px;
  padding-left: 6px;
  padding-top: 3px;
  font-size: 8px;
}

.panel-header {
  font-size: 11px;
}

.panel-body {
  font-size: 12px;
}

.action-dropdown {
  font-size: 12px;
}

.meta-table {
  height: 30px;
}

.forms-check[type="checkbox"] {
  background-color: #04C142;
}

.form-label {
  color: #04C142;
}

/* ----------------------------------------------------------------------------   */
/* Details.css */
.detail-container {
  z-index: 10;
}

.detailview-panel {
  font-size: 12px;
}

/* ----------------------------------------------------------------------------   */
/* DetailsPerformance.css */
.performance-dropdown {
  background-color: rgba(54, 70, 94);
}

/* ----------------------------------------------------------------------------   */
/* DetailsComments.css */
.error {
  border: 2px solid red !important;
}

/* ----------------------------------------------------------------------------   */
/* AssetUpload.css */
.left-section {
  background-color: rgba(4, 16, 29, 0.5);
}

.right-section {
  background-color: rgba(4, 16, 29, 0.5);
  font-size: small;
}

.asset-inputs {
  background-color: rgba(4, 16, 29, 0.5);
  color: white;
}

.asset-inputs:focus {
  background-color: rgba(4, 16, 29, 0.5);
  color: white;
}

.file-status {
  background-color: rgba(4, 16, 29, 0.5);
}

.iframe {
  contain: strict;
  display: block;
  overflow: hidden;
  /* Hides scrollbars */
}

.activate-button {
  padding-inline: 20px;
}

/* ----------------------------------------------------------------------------   */
/* PerformanceMetrics.css */
.performance-metric-modal .modal-header {
  background-color: #f8f9fa;
}

.performance-metric-modal .modal-title {
  font-weight: bold;
}

.inactive-tab {
  color: rgba(255, 255, 255, 0.703) !important;
  background-color: transparent;
}

.inactive-tab:hover {
  color: white !important;
}

.nav-tabs .nav-link.active {
  background-color: rgba(242, 243, 246, 0.052);
  border-bottom: none;
  color: white !important;
  border-color: rgba(242, 243, 246, 0.052);
}

.tab-content.active-shaded,
.tab-pane.active-shaded {
  background-color: rgba(242, 243, 246, 0.052);
  border: none;
  border-radius: 4px;
  transition: background-color 0.15s linear, border-color 0.15s linear;
}

.custom-alert {
  font-size: 12px; /* Smaller font size */
  padding: 8px 12px;
  margin: 5px 0;
}

/* ----------------------------------------------------------------------------   */
/* IntegragtionMap.css */

/* .column-header {
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  background-color: #3f7f3f;
  color: white;
  padding: 5px;
}

.box {
  font-size: x-small;
  background-color: #a2d9a2;
  border: 1px solid #000;
  border-radius: 5px;
  text-align: center;
  margin: 10px auto;
  padding: 10px;
  color: #000;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.box.grayed-out {
  color: #6c757d; 
  cursor: not-allowed; 
  pointer-events: none; 
  opacity: 0.6; 
}

.integrationmap-card {
  max-width: 274px;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
} */

/* ----------------------------------------------------------------------------   */
/* Profile.css */
.profile-card {
  background-color: rgba(4, 16, 29, 0.5) !important;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 500px;
  height: 350px;
}

.profile-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

/* ----------------------------------------------------------------------------   */
/* JourneyMaps.css */
.custom-accordion .accordion-body {
  background-color: #162D49;
  color: white;
}

.custom-accordion .accordion-button {
  background-color: #162D49;
  color: white;
}

.accordion-button::after {
  filter: invert(100%);
}

.custom-bg {
  background-color: #162D49;
}

.editing-input {
  background-color: #162D49 !important;
  color: white !important;
}

.white-labels .form-label {
  color: white;
  font-weight: bold;
}

.journeymap-card {
  max-width: 250px;
}

.cardview-img {
  object-fit: cover;
  height: 150px;
}

.draggable-line:hover {
  stroke-width: 4;
  cursor: ew-resize;
}

/* ----------------------------------------------------------------------------   */
/* Loader.css */
.loading {
  --speed-of-animation: 0.9s;
  --gap: 6px;
  --first-color: #cf0000;
  --second-color: #ff6344;
  --third-color: #FFFF00;
  --fourth-color: #b2ff6b;
  --fifth-color: #00a318;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  gap: 6px;
  height: 100px;
 }
 
 .loading span {
  width: 4px;
  height: 50px;
  background: var(--first-color);
  animation: scale var(--speed-of-animation) ease-in-out infinite;
 }
 
 .loading span:nth-child(2) {
  background: var(--second-color);
  animation-delay: -0.8s;
 }
 
 .loading span:nth-child(3) {
  background: var(--third-color);
  animation-delay: -0.7s;
 }
 
 .loading span:nth-child(4) {
  background: var(--fourth-color);
  animation-delay: -0.6s;
 }
 
 .loading span:nth-child(5) {
  background: var(--fifth-color);
  animation-delay: -0.5s;
 }
 
 @keyframes scale {
  0%, 40%, 100% {
   transform: scaleY(0.05);
  }
 
  20% {
   transform: scaleY(1);
  }
 }

 .loading-text {
  color: white;
  font-size: 14pt;
  font-weight: 600;
  margin-left: 10px;
}

.dot {
  margin-left: 3px;
  animation: blink 1.5s infinite;
}
.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes blink {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
